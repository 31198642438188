@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-border {
  border: none;
}

.grey-border {
  border: 1px solid grey !important;
}

.clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.popper {
  width: calc(100% - 32px);
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.svh-half {
  height: 50svh;
}

.svh-ninety {
  height: 90svh;
}
