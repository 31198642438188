.App {
  text-align: center;
  background-color: rgba(24, 24, 26, 1);
}

body {
  height: 100svh;
  max-height: 100svh;
  max-width: 100svw;
  width: 100svw;
  overflow: hidden;
}

#root {
  height: 100svh;
  max-height: 100svh;
  max-width: 100svw;
  width: 100svw;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.overflow {
  overflow-y: scroll;
  max-height: 100svh;
}

.overflow-scroll {
  overflow-y: scroll;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100%;
}

.half-gutter-bottom {
  margin-bottom: 8px;
}

.quarter-gutter-bottom {
  margin-bottom: 4px;
}

button {
  color: inherit;
  background-color: inherit;
  border: none;
}

#display-method-select-select {
  text-transform: capitalize;
}
